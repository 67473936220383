/**
 * @param el
 * @param focusableElementList
 */
export function addTrapFocusToElement(el: HTMLElement | undefined, focusableElementList: string = focusableElements): void {
  const firstFocusableElement = el?.querySelectorAll(focusableElementList)[0] as HTMLElement;
  firstFocusableElement?.focus();
  el?.addEventListener('keydown', function (event) {
    const focusableContent = el?.querySelectorAll(focusableElementList) ?? [];
    const lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement;
    const isTabPressed = event.key === 'Tab';
    if (!isTabPressed) {
      return;
    }

    if (event.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        event.preventDefault();
      }
      // if tab key is pressed
    } else if (document.activeElement === lastFocusableElement) {
      firstFocusableElement?.focus();
      event.preventDefault();
    }
  });
}

export const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
export const modalTitleElements = 'h1, h2, h3, h4, h5, h6, p';

// el is the parent of the list of elements we want
export function getFocusableElements(el: HTMLElement) {
  const focusableEls = el.querySelectorAll(focusableElements);

  return focusableEls ? [...focusableEls].filter((el) => el.getAttribute('tabindex') !== '-1') : undefined;
}

// el is the parent of the list of elements from witch we want the first element
export function getFirstFocusableElement(el: HTMLElement) {
  const focusableNodeList = getFocusableElements(el);
  return focusableNodeList ? focusableNodeList[0] : undefined;
}

// el is the currently focused element
export function getPreviousFocusableElement(el: HTMLElement, elList: HTMLElement[]) {
  const currentIndex = elList?.findIndex((listEl) => listEl === el);
  const previousIndex = (currentIndex - 1) % elList?.length >= 0 ? (currentIndex - 1) % elList?.length : elList?.length - 1;
  return elList[previousIndex];
}

// el is the currently focused element
export function getNextFocusableElement(el: HTMLElement, elList: HTMLElement[]) {
  const currentIndex = elList.findIndex((listEl) => listEl === el);
  const nextIndex = (currentIndex + 1) % elList?.length;
  return elList[nextIndex];
}

/**
 *
 * @param el
 */
export function removeTrapFocusToElement(el: HTMLElement | undefined): void {
  el?.removeEventListener('keydown', () => null);
}
